import { ReactNode, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap"
import { Calendar as BigCal, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { CalEvent, Event } from "../Home";
import DateItem from "../../Components/DateItem";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Employee } from "../../App";
interface Props {
    events: Event[]
    allEvents: Event[]
    updateRequest: (status: string, ids: GridRowSelectionModel) => void
    bankHolidays: CalEvent[]
    employees: Employee[]
}
const DashHr = ({ events, employees, updateRequest, bankHolidays, allEvents }: Props) => {
    const [viewing, setViewing] = useState<boolean>(false)
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>()
    const [selectedEvent, setSelectedEvent] = useState<Event>()
    const [manager, setManager] = useState<string>("")
    const [calEvents, setCalEvents] = useState<CalEvent[]>([])
    const localizer = momentLocalizer(moment)
    const tabletMedia = window.matchMedia("(max-width: 1250px)").matches

    const formatCalEvents = () => {
        const temp: CalEvent[] = bankHolidays.filter((a) => a.title.includes("Bank Holiday") || a.title.includes("Christmas Shutdown Period") || a.title.includes("Extra leave - ES 25th Birthday"))
        events.map((item) => {
            if(item.daysTaken <= 1) {
                temp.push({
                    title: `${item.employee.name} - ${item.type} - ${item.managerStatus}`,
                    end: new Date(item.start),
                    start: new Date(item.start),
                    allDay: true
                })
            }else {
                temp.push({
                    title: `${item.employee.name} - ${item.type} - ${item.managerStatus}`,
                    end: new Date(item.end),
                    start: new Date(item.start),
                    allDay: true
                })
            }
        })
        setCalEvents(temp)
    }

    useEffect(() => {
        formatCalEvents()
    }, [])

    useEffect(() => {
        if(selectedEvent) {
            const filtEmp = employees.filter((item) => item.email.toLowerCase() === selectedEvent.employee.email.toLowerCase())
            console.log(filtEmp[0])
            setSelectedEmployee(filtEmp[0])
        }
    }, [selectedEvent])

    const getDaysUsed = (emp: Employee) => {
        console.log(events)
        let total = 0
        allEvents.map((event) => {
            if (event.hrStatus === "Approved" && event.managerStatus === "Approved" && event.employee.email.toLowerCase() === emp.email.toLowerCase() && (event.type === "Annual Leave" || event.type === "Medical" || event.type === "In-Lieu")) {
                console.log(event)
                total = total + event.daysTaken
            }
        })
        total = total + emp.xmas
        return total
    }
    
    return (
        <Row className="h-100">
            <Col sm={6} className={`d-flex flex-column ${tabletMedia && "w-100"}`}>
                {events.length > 0 ?
                    <>
                        {events.map((item) => {
                            return <DateItem onClick={(event) => {
                                setSelectedEvent(event)
                                setManager(`${event.manager}`)
                                setViewing(true)
                            }} event={item} />
                        })}
                    </>
                    :
                    <div className="d-flex justify-content-center">
                        <p>No holiday approvals are pending</p>
                    </div>
                }
            </Col>
            <Col sm={6}>
                <div className={viewing ? "dash-cal dash-cal--edit" : "dash-cal"}>
                    {!tabletMedia &&
                        <BigCal
                            localizer={localizer}
                            events={calEvents}
                            toolbar={true}
                            popup
                        />
                    }
                    {viewing &&
                        <>
                            <Row className="mb-3 mt-3">
                                <Col>
                                    <p className="form-label">Employee:</p>
                                    <p>{selectedEvent?.employee.name}</p>
                                </Col>
                                <Col>
                                    <p className="form-label">Start Date:</p>
                                    <p>{moment(selectedEvent?.start).format("DD/MM/YYYY")}</p>
                                </Col>
                                <Col>
                                    <p className="form-label">End Date:</p>
                                    <p>{moment(selectedEvent?.end).format("DD/MM/YYYY")}</p>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            if (selectedEvent) {
                                                updateRequest("Approved", [selectedEvent?.id])
                                            }
                                            setSelectedEvent(undefined)
                                            setViewing(false)
                                            formatCalEvents()
                                        }}
                                        className="buttonHS buttonHS--green">Approve Request</Button>

                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <p className="form-label">Days Remaining:</p>
                                    <p>{selectedEmployee && `${(selectedEmployee.entitlement + selectedEmployee.daysForService + selectedEmployee.carryOver + selectedEmployee.inLieu) - getDaysUsed(selectedEmployee)} Days`}</p>
                                </Col>
                                <Col>
                                    <p className="form-label">Leave Type:</p>
                                    <p>{selectedEvent?.type}</p>
                                </Col>
                                <Col>
                                    <p className="form-label">Days Taken:</p>
                                    <p>{selectedEvent?.daysTaken} Days</p>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            if (selectedEvent) {
                                                updateRequest("Rejected", [selectedEvent?.id])
                                            }
                                            setSelectedEvent(undefined)
                                            setViewing(false)
                                            formatCalEvents()
                                        }}
                                        className="buttonHS buttonHS--red">Reject Request</Button>

                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <p className="form-label">Manager:</p>
                                    <Form.Group controlId="raiseTicket.summary">
                                        <Form.Control value={manager} onChange={(value) => { setManager(value.currentTarget.value) }} placeholder="Enter a manager..." />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <p className="form-label">Comments:</p>
                                    <Form.Group controlId="raiseTicket.summary">
                                        <Form.Control value={""} onChange={(value) => { }} placeholder="Enter a comment..." />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }

                </div>
                <div>

                </div>
            </Col>
        </Row>
    )
}

export default DashHr