import { Row, Col } from "react-bootstrap"
import { Calendar as BigCal, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { CalEvent, Event } from "../Home";
import { Employee } from "../../App";
import { useEffect, useState } from "react";
import DateItem from "../../Components/DateItem";
interface Props {
    events: Event[]
    employee: Employee
    manEvents: Event[]
    bankHolidays: CalEvent[]
}
const DashAll = ({ events, employee, manEvents, bankHolidays }: Props) => {
    const [calEvents, setCalEvents] = useState<CalEvent[]>([])
    const localizer = momentLocalizer(moment)
    const tabletMedia = window.matchMedia("(max-width: 1250px)").matches
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const getDaysUsed = () => {
        let total = 0
        events.map((event) => {
            if (event.hrStatus === "Approved" && event.managerStatus === "Approved" && (event.type === "Annual Leave" || event.type === "Medical" || event.type === "In-Lieu")) {
                total = total + event.daysTaken
            }
        })
        total = total + employee.xmas
        return total
    }

    useEffect(() => {
        const temp: CalEvent[] = bankHolidays.filter((a) => a.title.includes("Bank Holiday") || a.title.includes("Christmas Shutdown Period") || a.title.includes("Extra leave - ES 25th Birthday"))
        const all: Event[] = [...events, ...manEvents]
        all.map((item) => {
            if(item.hrStatus !== "Rejected") {
                if(item.daysTaken <= 1) {
                    temp.push({
                        title: `${item.employee.name} - ${item.type} - ${item.managerStatus} - ${item.reason}`,
                        end: new Date(item.start),
                        start: new Date(item.start),
                        allDay: true
                    })
                } else {
                    temp.push({
                        title: `${item.employee.name} - ${item.type} - ${item.managerStatus} - ${item.reason}`,
                        end: new Date(item.end),
                        start: new Date(item.start),
                        allDay: true
                    })
                }
            }
        })
        setCalEvents(temp)
    }, [events, manEvents])

    return (
        <Row className={`h-100 ${tabletMedia && "flex-column"}`}>
            <Col sm={2} className="dash-all">
                <div className="dash-all-card">
                    <p className="dash-all-card__number">{employee.entitlement + employee.daysForService + employee.carryOver + employee.inLieu}</p>
                    <p className="dash-all-card__text">Days Entitlement</p>
                </div>
                <div className="dash-all-card">
                    <p className="dash-all-card__number">{getDaysUsed()}</p>
                    <p className="dash-all-card__text">Days Used</p>
                </div>
                <div className="dash-all-card">
                    <p className="dash-all-card__number">{(employee.entitlement + employee.daysForService + employee.carryOver + employee.inLieu) - getDaysUsed()}</p>
                    <p className="dash-all-card__text">Days Remaining</p>
                </div>
            </Col>
            <Col sm={10} className={`${tabletMedia && "w-100"}`}>
                {tabletMedia &&
                <p className="font-bold text-center temp">Recent holiday requests</p>
                }
                {tabletMedia ?
                    <>
                        {events.map((item) => {
                                return <DateItem statusFormat onClick={(event) => { console.log(event)}} event={item} />

                        })}
                    </>
                    :
                    <BigCal
                        localizer={localizer}
                        events={calEvents}
                        toolbar={true}
                        popup
                    />
                }
            </Col>
        </Row>
    )
}

export default DashAll